import util from 'util';
import { isDev, isTest, ResponseError, ResponseErrorEnum } from 'common-lib';
import { deleteAuthorizedCookie } from './cookie';

export async function handleFetchError(response, method, url): Promise<Response | undefined> {
	if (response.status === 403) {
		deleteAuthorizedCookie();
		window.location.replace('');
		return Promise.reject();
	}

	if (response.status >= 400) {
		let json;
		try {
			json = await response.json();
		} catch (_) {
			// todo
		}
		throw new ResponseError(
			response.status,
			ResponseErrorEnum.ERROR_CODE_SERVER_ERROR,
			response.status === 504 ? 'Сервер не доступен'
				: json?.error || util.format(
				`Сервер вернул "%s %s" при запросе: %s %s. Ответ от сервера: %s`,
				response.status,
				response.statusText,
				method,
				url,
				json ? JSON.stringify(json) : '(пусто)'),
			json,
		);
	}
}

export default async function fetchBackend(method, url, body?, fetchOptions?): Promise<Response> {
	const { headers: inputHeaders, ...options } = fetchOptions || {};
	url = `/api/${url}`;
	method = method.toUpperCase();
	const headers = {
		Accept: 'application/json',
	};
	const isContentTypeJson = (body && !(body instanceof FormData));
	if (isContentTypeJson) {
		headers['Content-Type'] = 'application/json';
	}
	if (inputHeaders) {
		Object.assign(headers, inputHeaders);
	}

	const response = await fetch(url, {
		...options,
		method,
		headers,
		body: isContentTypeJson ? JSON.stringify(body) : body,
	})
		.catch(error => {
			throw new ResponseError(
				0,
				ResponseErrorEnum.ERROR_CONNECTION_ERROR,
				(isDev || isTest) ? error.stack: 'Ошибка соединения с сервером. Проверьте интернет соединение',
			);
		});

	return await handleFetchError(response, method, url) || response;
}

function fetchGetThenReadBody(url, body?, options?) {
	return fetchBackend('get', url, body, options)
		.then((response: any) => response.blob())
		.then(blob => blob.text());
}

function fetchGetThenJson(url, body?, options?) {
	return fetchBackend('get', url, body, options)
		.then((response: any) => response?.json());
}

function fetchGetThenDataFromJson(url, body?, options?) {
	return fetchGetThenJson(url, body, options)
		.then(({ data }) => data);
}

function fetchPost(url, body?, options?) {
	return fetchBackend('post', url, body, options);
}

function fetchPostThenJson(url, body?, options?) {
	return fetchBackend('post', url, body, options)
		.then((response: any) => response?.json());
}

function fetchPut(url, body?, options?) {
	return fetchBackend('put', url, body, options);
}

function fetchPutThenJson(url, body?, options?) {
	return fetchBackend('put', url, body, options)
		.then((response: any) => response?.json());
}

function fetchDelete(url, body?, options?) {
	return fetchBackend('delete', url, body, options);
}

export {
	fetchGetThenReadBody,
	fetchGetThenJson,
	fetchGetThenDataFromJson,
	fetchPost,
	fetchPostThenJson,
	fetchPut,
	fetchPutThenJson,
	fetchDelete,
};

import React from 'react';
import './styles/style.less';
import { render } from 'react-dom';
import { App } from './containers';

// @ts-ignore
module.hot?.accept();

function initApp() {
	render(
		<App />,
		document.getElementById('container'),
	);
}

window.addEventListener('load', initApp);
